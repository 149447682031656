
import Vue from 'vue'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies';
import VueMask from 'v-mask'

import modules from './modules/module';
import widgets from './widgets/widget';
import workers from './workers/worker';
import service from './service/main';


/* document.addEventListener('DOMContentLoaded', e => {
  console.log(document.getElementById('app'))
}) */
//Vue.use(VueAppend)
Vue.prototype.$axios = axios.create({
  baseURL: 'https://vevanta.ru/api/',
})

Vue.config.productionTip = false
Vue.use(VueCookies);
Vue.use(VueMask);

let components = {
  'preloader': () => import('./components/Preloader'),
  'UtmSource': () => import('./components/UtmSource'),
  'Shorts': () => import('./components/Cards/Shorts/shorts'),
  'clients': () => import('./components/clients/index'),
  'SlotFixed': () => import('./components/SlotFixed'),
  'SlotModal': () => import('./components/SlotModal'),
  'TextOverflow': () => import('./components/TextOverflow'),
  'textOverflows': () => import('./components/TextOverflows'),
  'PhotoReport': () => import('./components/PhotoReport'),
  'TargetGoal': () => import('./components/TargetGoal'),
  ...modules,
  ...widgets,
  ...workers,
  ...service,
}

let vm = '';
let render = () => {

   vm = new Vue({
    store,
    el: '#app',
    components,
  }).$mount()
}


render()



  let wc = Object.keys(components).map(item => {
    return item.toUpperCase();
  })


    let observer = new MutationObserver((records) => {

      records.forEach(item => {
        if (item.addedNodes.length) {
          item.addedNodes.forEach((item, i) => {

          if (!item.tagName) return
          let tagName = item.tagName.replace('-', '').toUpperCase();

         if (wc.includes(tagName) || item.hasAttribute('builder')) {
           item.removeAttribute('builder')
             let div = document.createElement('div')
             item.replaceWith(div)
             div.appendChild(item)

              new Vue({
               store,
               el: div,
               components,
             }).$mount()

           }
          });

        }
      })
    });


    observer.observe(document.getElementById('app'), {
      childList: true, // наблюдать за непосредственными детьми
      subtree: true, // и более глубокими потомками
      characterDataOldValue: true // передавать старое значение в колбэк
    });
