export default {
  'MapPolygon': () => import('./Map/Polygon/IndexModule'),

  'Calc': () => import('./Calc/Calc'),
  'CalcHouse': () => import('./CalcHouse/CalcHouse'),
  'Galery': () => import('./Gallery/Galery'),
  'Gallery': () => import('./Gallery/Gallery'),
  'ProjectListFilter': () => import('./Project/ProjectListFilter'),
  'Projects': () => import('./Project/Projects'),
  'ProjectsId': () => import('./Project/ProjectsId'),
  'ProjectList': () => import('./Project/ProjectList'),
  'ProjectsListSort': () => import('./Project/ProjectsListSort'),
  'ProjectCounts': () => import('./Project/ProjectCounts'),
  'QuizProject': () => import('./QuizProject/QuizProject'),
  'Cart': () => import('./Cart/Cart'),
  'CalcDetails': () => import('./CalcDetails/CalcDetails'),

  'ConsultationTwo': () => import('./Form/ConsultationTwo'),
  'Consultation': () => import('./Form/Consultation'),
  'FormTwo': () => import('./Form/FormTwo'),
  'IpotekaModern': () => import('./Form/Ipoteka/Calc'),

  'NavBottom': () => import('./NavBar/NavBottom'),
  'VideoDesctop': () => import('./Video/VideoDesctop'),

  'Youtube': () => import('./Youtube/video'),
  'PortfolioList': () => import('./Portfolio/List'),

  'MapPrice': () => import('./Map/MapPrice'),
  'RenderModule': () => import('./Map/Render/RenderModule'),
}
